import { React, useEffect, useState } from 'react';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import "../footer.css";
import companyLogo from "../images/logo1.png";
import location from "../images/location_1.png";
import call from "../images/call_1.png";
import mail from '../images/mail.png';
import fb from "../images/fb.png";
import linkedin from "../images/linkedin.png";
import twitter from "../images/twitter.png";
import android from "../images/android.png";
import office from "../images/oppice.png";
import ios from "../images/ios.png";
import { NavLink } from 'react-router-dom';
import { Link } from "react-scroll";
import ScrollToTop from "react-scroll-to-top";
import { FaRegPaperPlane } from "react-icons/fa";
// import $ from 'jquery';
import { MultiSelect } from "react-multi-select-component";
import axios from "axios";
import CustomToast from './CustomToast';
// import emailjs from "emailjs-com";
const Footer = () => {
    const options=[
        {
            "label": "General Query",
            "value": "General Query"
        },
        {
            "label": "Warehouse Management System",
            "value": "Warehouse Management System"
        },
        {
            "label": "Transport Management System",
            "value": "Transport Management System"
        },
        {
            "label": "End To End Sku Tracking",
            "value": "End To End Sku Tracking"
        },
        {
            "label": "3rd Party Api Service",
            "value": "3rd Party Api Service"
        },
        {
            "label": "Blockchain Based Tracking",
            "value": "Blockchain Based Tracking"
        },
        {
            "label": "Real Time Tracking",
            "value": "Real Time Tracking"
        },
        {
            "label": "Container Terminal Automation",
            "value": "Container Terminal Automation"
        },
        {
            "label": "Manpower Tracking",
            "value": "Manpower Tracking"
        },
        {
            "label": "Camera Solutions",
            "value": "Camera Solutions"
        },
        {
            "label": "Rfid Solutions",
            "value": "Rfid Solutions"
        },
        {
            "label": "Gate And Factory Automation",
            "value": "Gate And Factory Automation"
        },
        {
            "label": "Dock And Bay Management",
            "value": "Dock And Bay Management"
        },
        {
            "label": "Route And Load Optimisation",
            "value": "Route And Load Optimisation"
        },
        {
            "label": "D2c Shipping Services",
            "value": "D2c Shipping Services"
        }
    ]

    const [selected, setSelected] = useState([])
    const [popupEmail, setPopupEmail] = useState('');
    const [toast, setToast] = useState({ message: "", type: "" });
    const [pop_up, set_pop_up] = useState(false);

    const show_pop_up = () => {
        const validateEmail = (email) => {
            const re = /\S+@\S+\.\S+/;
            return re.test(String(email).toLowerCase());
        };
        const emailValidated = validateEmail(popupEmail);
        if (emailValidated) {
            set_pop_up(!pop_up);
            if(pop_up){
                send_email({email:popupEmail})
            }
        }

    }

    const showToast = (message, type) => {
        setToast({ message, type });
        setTimeout(() => setToast({ message: "", type: "" }), 5000);
    };

    const send_email = (obj=null, callbackFn)=>{
        axios({
            url: "https://api.lynkit.in/api/contact-us",
            method: "POST",
            data: obj
        })
        .then((res) => {
            if(res?.data?.success){
                showToast("Form submitted successfully.", "success")
                callbackFn();
            }else{
                showToast(res?.data?.message || "Some Error Occured.", "error")
            }
         })
        .catch((err) => { 
            showToast("Some Error Occured.", "error")
        })
    }

    function send_email_submit(e) {
        e.preventDefault();
        // emailjs.sendForm('service_qv840ig', 'template_ip8beyb', e.target, 'user_EVd7ISKUXA4KObh8kHWqN')
        //     .then((result) => {
        //         console.log(result.text);
        //     }, (error) => {
        //         console.log(error.text);
        //     });
        e.target.reset()
    }

    

    if (pop_up) {
        document.body.classList.add('active_pop_up')
    } else {
        document.body.classList.remove('active_pop_up')
    }

    function send_pop_up_data(e) {
        e.preventDefault();
        let temp1=e.target.elements;
        let obj={}
        for (let e = 0; e < temp1.length; e++) {
           if(['text','number'].includes(temp1[e].type)){
            obj[temp1[e].name]=temp1[e].value
           }
        }
        obj['email']=popupEmail
        obj['interest']=selected.map(el=>el.value)
        
        send_email(obj, () => {
            e.target.reset()
            set_pop_up(false)
            setPopupEmail('')
            setSelected([])
        })
        // emailjs.sendForm('service_nwezqpm', 'template_bc6naj9', e.target, 'user_RCpEDp1qBqHpZdCQZEBw1')
        //     .then((result) => {
        //         console.log(result.text);
        //     }, (error) => {
        //         console.log(error.text);
        //     });
        // e.target.reset()
        // alert("Form submitted")
    }

    const focus_email = () => {
        document.getElementById("text_field1").focus()
        alert("Please fill in the Email.")
    }

    const setSelectedvalue = (evt)=>{
        setSelected(evt)
    }

    const customValueRenderer = (selected) => {
        return selected.length
          ? selected.map(({ label }) => "✔️ " + label)
          : "No Items Selected";
    }

    return (
        <div className="footer_main">
            <ScrollToTop id="scrolling_to_top" smooth color="black" />
            <div className="footer_part1">
                <div className="footer_heading1">
                    <a onClick={focus_email}>
                        <h1>Schedule a Demo</h1><br />
                        <p>Share your contact details and our team will contact you shortly.</p>
                    </a>
                </div>

                <div className="email_1" >
                    <form onSubmit={send_email_submit}>

                        <input id="text_field1"   pattern="^[^\s@]+@[^\s@]+\.[^\s@]+$"  title="Please enter a valid email address." name="email" type="email" autoComplete="off" onChange={event => setPopupEmail(event.target.value)} placeholder="Enter Email" required />


                        <button onClick={() => show_pop_up()}  ><ArrowForwardIcon id="arrow2" /></button>

                    </form>
                </div>

            </div>

            {pop_up && (
                <div className="pop_up_outer">
                    <div 
                        className="overlay" 
                        onClick={(e) => {
                            e.preventDefault();
                            set_pop_up(false);
                        }}
                    >

                    </div>
                    <div className="form_div2" >
                        <h1> Get Started</h1>
                        <form onSubmit={send_pop_up_data}>
                            <div className='justify-content-around row'>
                                <div className='col-10'>
                                    <div className='row input-con'>
                                        <div className='col-12'>
                                            <label>Company Name</label> 
                                            <input type="text" className='form-control' name="company_name" placeholder="Company Name" required/>        
                                        </div>
                                    </div>
                                    <div className='row input-con'>
                                        <div className='col-12'>
                                            <label>First Name</label>     
                                            <input type="text" name="first_name" className="form-control" placeholder="First Name" required/>
                                        </div>
                                    </div>
                                    <div className='row input-con'>
                                        <div className='col-12'>
                                            <label>Last Name</label>     
                                            <input type="text" name="last_name" className="form-control" placeholder="Last Name" required/>
                                        </div>
                                    </div>
                                    <div className='row input-con'>
                                        <div className='col-12'>
                                            <label>Use cases interested in</label> 
                                            {/* <pre>{JSON.stringify(selected)}</pre> */}
                                            <div>
                                                <MultiSelect options={options} value={selected} onChange={(evt)=>{setSelectedvalue(evt)}} labelledBy={"Select"} isCreatable={true}/>
                                            </div>       
                                        </div>
                                    </div>
                                    <div className='row input-con'>
                                        <div className='col-12'>
                                            <label>Phone</label>     
                                            <input type="number" name="number" className="form-control" placeholder="+91 123456789" required/>
                                        </div>
                                    </div>
                                    <div className='row input-con'>
                                        <div className='col-12'>
                                            <label>Message</label>    
                                            <textarea type="text" name="message" className="form-control" placeholder='Any message'></textarea>
                                        </div>
                                    </div>
                                    <div className='row input-con'>
                                        <div className='col-2'></div>
                                        <div className='col-4'>
                                            <button className='btn_sb' type="submit" ><FaRegPaperPlane id="plane" /></button>
                                        </div>
                                        <div className='col-4'>
                                            <button className='btn_sb' type="button" onClick={(e) => {
                                                e.preventDefault();
                                                set_pop_up(false);
                                            }}> Close</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            )}




            <div className="footer_new_part">
                <div className="footer_logo">
                    <a href="/Home"> <img id="logo_footer" src={companyLogo} alt="logo" /></a>
                </div>


                <div className="data_footer1">
                    <div className="data_footer_1">
                        <div className="location_all">
                            <a href="https://www.google.com/maps/dir//lynkit/@28.5254549,77.2054582,12z/data=!3m1!4b1!4m9!4m8!1m1!4e2!1m5!1m1!1s0x390ce1656fbb7b65:0x7f0e4b2c21935a5c!2m2!1d77.2754985!2d28.5254729" target="_blank"><img id="location" src={location} alt="none" /></a>
                            <img id="locationM1"  src={office} alt="none" />
                            <img id="locationM" src={call} alt="none" />
                            <img id="locationM" src={mail} alt="none" />
                        </div>
                    </div>

                </div>

                <div className="addressdiv">
                    <div className='addressElem'>
                        <a id="addresss" href="https://www.google.com/maps/dir//lynkit/@28.5254549,77.2054582,12z/data=!3m1!4b1!4m9!4m8!1m1!4e2!1m5!1m1!1s0x390ce1656fbb7b65:0x7f0e4b2c21935a5c!2m2!1d77.2754985!2d28.5254729" target="_blank"> 246, Block A, Okhla Industrial Area Phase I, New Delhi-110020.</a><br />
                        <a id="addresss2" href="https://www.google.com/maps/dir//Aviationfly+-+Unit+208,+Level+1,+Gate+Avenue+-+South+Zone+Dubai+International+Financial+Center+507211+-+Dubai+-+United+Arab+Emirates/@25.2134854,55.273253,16z/data=!4m9!4m8!1m0!1m5!1m1!1s0x3e5f430a51b4573b:0x3fdbda557be6195f!2m2!1d55.2775618!2d25.2080586!3e0" target="_blank"> Unit 208, Level 1 Gate Avenue - South Zone, Dubai International Financial Centre, Dubai, UAE.</a><br />
                    </div>
                    <a id="addresss_1" href="tel:+91 11 4082 4028">+91 11 4082 4028</a>
                    <a id="addresss_2" href="mailto:contact@lynkit.in">contact@lynkit.in</a>
                </div>


























                <div className="data_footer3">
                    <p>Our Products</p>
                    <a className="data_footer3_1" href="https://www.lynktrac.io/" target="_blank"> Lynktrac®</a>
                    <a className="data_footer3_2" href="https://lynkit.io/" target="_blank"> Lynkit.io™</a>
                    <a className="data_footer3_3" href="https://www.lynkgrid.com/" target="_blank"> Lynkgrid®</a>
                    <a className="data_footer3_3" href="https://wms.lynkgrid.com/" target="_blank"> Lynkgrid®- Warehouse</a>

                    <a className="data_footer3_4" target="_blank"> LynkID©</a>
                    <a className="data_footer3_4" href="https://www.pikmybox.com/" target="_blank"> PikMyBox™</a>
                </div>
                <div className="data_footer4">
                    <p>Company</p>
                    <Link
                        to="main_company"
                        spy={true}
                        smooth={true}
                        offset={-70}
                        duration={500}
                    >
                        <NavLink className="data_footer4_one" to="/About_us/aboutUs" >About Us</NavLink><br />
                    </Link>
                    <Link
                        to="customers_"
                        spy={true}
                        smooth={true}
                        offset={-70}
                        duration={500}
                    >
                        <NavLink className="data_footer4_one" to="/About_us/partners" >Our Partners </NavLink><br />
                    </Link>
                    <Link
                        to="Advisory_main"
                        spy={true}
                        smooth={true}
                        offset={-70}
                        duration={500}
                    >
                        <NavLink className="data_footer4_two" to="/Advisory_Board" >Advisory Board </NavLink><br />
                    </Link>
                    <NavLink className="data_footer4_three" to="/ContactUs" >Contact Us </NavLink><br />
                </div>

                <div className="data_footer5">
                    <p>Connect With Us</p>
                    <div className="social">
                        <a target="_blank" href="https://www.facebook.com/LynkitIndia" > <img src={fb} alt="none" /></a>
                        <a target="_blank" href="https://www.linkedin.com/authwall?trk=ripf&trkInfo=AQFDdEZYJhmMPQAAAXo4RtjIpsDBX8MVvY402c7ESFKSQBOXcx8nhR6JUAfwR36JcT--FjUC1wFllcg9cG-Bp8ilT8W_IUGLl5IE4fZf4NJmSrepair4QAM4n-APKKhTtBcvLIA=&originalReferer=https://www.lynkit.in/&sessionRedirect=https%3A%2F%2Fin.linkedin.com%2Fcompany%2Flynkit-india"> <img src={linkedin} alt="none" /></a>
                        <a target="_blank" href="https://twitter.com/Lynkitindia"> <img src={twitter} alt="none" /></a>
                    </div>
                    <a target="_blank" href="https://play.google.com/store/apps/developer?id=Lynkit.&hl=en"><img id="android" src={android} alt="none" /></a>
                    <a target="_blank" href="https://apps.apple.com/us/developer/lynkit/id1455816051"><img id="android" src={ios} alt="none" /></a>
                </div>
            </div>
            <div className="last_foot">
                <p>Copyright ©  2019 Lynkit. All Rights Reserved</p>
            </div>

            <CustomToast
                message={toast.message}
                type={toast.type}
                onClose={() => setToast({ message: "", type: "" })}
            />
        </div>
    );
};
export default Footer;