import React from "react";
import ReactDOM from "react-dom";
import styles from "./style.module.css"; // Import custom styles for toast

// Toast Component
const CustomToast = ({ message, type, onClose }) => {
  if (!message) return null;

  return ReactDOM.createPortal(
    <div className={`${styles.custom_toast} ${styles[type]}`}>
      <span>{message}</span>
      <button className={styles["toast_close-btn"]} onClick={onClose}>
        &times;
      </button>
    </div>,
    document.body
  );
};

export default CustomToast;