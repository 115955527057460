import React, { useState } from "react";
import styles from "./styles.module.css";
import { StyledHeading } from "../../HelperComponents";
import portable_gps_lock from "../../assets/hardware/portable_gps_lock.svg";
import asset_tracker from "../../assets/hardware/asset_tracker.svg";
import OBD_tracker from "../../assets/hardware/OBD_tracker.svg";
import fixed_lock from "../../assets/hardware/fixed_lock_new.svg";
import radar_sensor from "../../assets/hardware/radar_sensor.svg";
import temperature_sensors from "../../assets/hardware/temperature_sensors.svg";
import ADAS_camera from "../../assets/hardware/adas_camera_new.svg";
import fuel_sensor from "../../assets/hardware/fuel_sensor.svg";
import dms_camera from "../../assets/hardware/cargo_camera.svg";
import bluetooth_lock from "../../assets/hardware/bluetooth_lock.svg";
import fixed_tracker from "../../assets/hardware/fixed_tracker.svg";
import cargo_camera from "../../assets/hardware/cargo_camera_new.svg";
import { SlideUpComponent } from "../../AnimationConents";

const products = [
  {
    id: 1,
    title: "Portable GPS Lock",
    image: portable_gps_lock,
    details:
      "Lynkit GPS locks with 4G connectivity, 2G fallback, dual sim support and 2.5M accuracy.",
  },
  {
    id: 2,
    title: "Asset Trackers",
    image: asset_tracker,
    details:
      "GPS asset trackers with rechargeable batteries built-in 7500mAh or 10000mAh configurations.",
  },
  {
    id: 3,
    title: "OBD Tracker",
    image: OBD_tracker,
    details:
      "OBD vehicle tracking with 4G communication/2G fallback and GPS tracking.",
  },
  {
    id: 4,
    title: "Fixed Lock",
    image: fixed_lock,
    details:
      "Lynkit fixed locks with 4G connectivity, 2G fallback, and an incorrect password-locking system.",
  },
  {
    id: 5,
    title: "Radar Sensor",
    image: radar_sensor,
    details: "Radar sensor for collision avoidance.",
  },

  {
    id: 6,
    title: "Temperature Sensor",
    image: temperature_sensors,
    details: "Reefer or a truck, we have you covered! Our wired and wireless sensors offer zone-wise temperature detection.",
  },

  {
    id: 7,
    title: "ADAS Camera",
    image: ADAS_camera,
    details: "AI network-based camera supports H256 streaming, humanoid detection, and 4G communication.",
  },

  {
    id: 8,
    title: "Fuel Sensor",
    image: fuel_sensor,
    details: "The fuel sensor is used to get consumption patterns and mileage calculations.",
  },

  {
    id: 9,
    title: "DMS Camera",
    image: dms_camera,
    details:
      "DMS camera with voice indication in the cabin for real-time alerts.",
  },

  {
    id: 10,
    title: "Cargo Camera",
    image: cargo_camera,
    details:
      "The cargo camera supports H256 streaming 1080p HD / built-in antenna and records playback.",
  },

  {
    id: 11,
    title: "Bluetooth Lock",
    image: bluetooth_lock,
    details: "Fingerprint Lock with Bluetooth Support.",
  },

  {
    id: 12,
    title: "Fixed Tracker",
    image: fixed_tracker,
    details: "4G communication with 2G fallback and GPS tracking.",
  },
];

const gradients = [
  styles.gradientTopLeft,
  styles.gradientBottomLeft,
  styles.gradientBottomRight,
  styles.gradientTopRight
];

const Hardware = () => {
  const [selectedProductId, setSelectedProductId] = useState(null);

  const handleProductClick = (id) => {
    setSelectedProductId(selectedProductId === id ? null : id);
  };

  const getRandomGradient = () => {
    return gradients[Math.floor(Math.random() * gradients.length)];
  };

  return (
    <div className={styles.hardware_container_outer}>
      <div>
        <StyledHeading text="Hardware Products" />
        <SlideUpComponent duration={1500}>
          <p className={styles.heading_para}>Explore The Awesome</p>
        </SlideUpComponent>
        
      </div>
      <div className={styles.hardware_products_container}>
        <div className={styles.product_grid}>
          {products.map((product) => (
            <div
              key={product.id}
              className={`${styles.product_box} ${selectedProductId === product.id ? styles.selected : ""}`}
              onClick={() => handleProductClick(product.id)}
            >
              <div
                className={`${styles.product_img_div} ${getRandomGradient()}`}
              >
                <img
                  src={product.image}
                  alt={product.title}
                  className={styles.product_image}
                />
              </div>

              <div
                className={`${styles.right_part} ${getRandomGradient()}`}
              >
                <h3 className={styles.product_title}>{product.title}</h3>

                {selectedProductId === product.id && (
                  <p className={styles.product_details}>{product.details}</p>
                )}
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Hardware;
